import { Flex } from "@chakra-ui/react";
import {
    useSitecoreContext,
    type ComponentParams,
    type ComponentRendering,
} from "@sitecore-jss/sitecore-jss-nextjs";
import SCText from "commons/sc/SCText";
import CookieConsent, { type CookieConsentFieldProps } from "commons/ui/CookieConsent";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import Script from "next/script";

export type ScriptEmbedProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: { ScriptUrl: { value: string }; Markup: { value: string } } & GenericHeaderProps &
        CookieConsentFieldProps;
};

const ScriptEmbed = (props: ScriptEmbedProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    const scriptUrl = props.fields?.ScriptUrl?.value;
    const html = props.fields?.Markup?.value;

    const cookieConsentFields = {
        AnalyticsCookies: props.fields?.AnalyticsCookies,
        FunctionalCookies: props.fields?.FunctionalCookies,
        MarketingCookies: props.fields?.MarketingCookies,
    };

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields.AnchorId}
            HeaderHeadline={props.fields?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.HeaderSubHeadline}
            HeaderCTA={props.fields?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            {pageEditing && (
                <Flex flexDirection="column" gap="sp16" mb="sp16">
                    <Flex flexDirection="column">
                        HTML:
                        <SCText
                            textField={props.fields?.Markup}
                            border="1px solid black"
                            mt="sp12"
                        />
                    </Flex>
                    <Flex flexDirection="column">
                        Script URL:
                        <SCText
                            textField={props.fields?.ScriptUrl}
                            border="1px solid black"
                            mt="sp12"
                            wordBreak="break-word"
                        />
                    </Flex>
                </Flex>
            )}
            <CookieConsent {...cookieConsentFields}>
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
                {scriptUrl && <Script src={scriptUrl}></Script>}
            </CookieConsent>
        </GenericHeader>
    );
};

export default ScriptEmbed;
