import { AspectRatio, Box, Stack, Link } from "@chakra-ui/react";
import BundleIcon from "commons/ui/BundleIcon";
import type { UseImageSizesParams } from "utils/hooks/useImageSizes";
import useImageSizes, { imageSize } from "utils/hooks/useImageSizes";
import SCHeading from "commons/sc/SCHeading";
import SCImage from "commons/sc/SCImage";
import SCLinkButton from "commons/sc/SCLinkButton";
import SCText from "commons/sc/SCText";
import NextLink from "next/link";
import type { Field, ImageField, LinkField } from "@sitecore-jss/sitecore-jss-nextjs";
import { useSitecoreContext } from "@sitecore-jss/sitecore-jss-nextjs"; // Added missing import for Url type
import SCLink from "commons/sc/SCLink";
import { useI18n } from "next-localization";
import { gtmCTAEvent, gtmCustomEvent } from "commons/head/GTMTracking";
import SCRichText from "commons/sc/SCRichText";

export interface TeaserFields {
    Kicker: Field<string>;
    Headline: Field<string>;
    Text: Field<string>;
    CTA: LinkField;
    Image: ImageField;
}

export interface TeaserCardProps {
    id?: string;
    fields: TeaserFields;
    variant: "columns2" | "columns3" | "columns4" | "imageRight" | "imageLeft";
    download?: boolean;
    pageEditing?: boolean;
}

function gridColumnValue(variant: string) {
    switch (variant) {
        case "imageLeft":
        case "imageRight":
            return "1 / -1";
        case "columns2":
            return "span 6";
        case "columns3":
            return "auto / span 4";
        case "columns4":
            return "auto / span 3";
        default:
            return undefined;
    }
}

const getVariantImageSize = (variant: string): UseImageSizesParams => {
    switch (variant) {
        case "columns2":
            return { base: 1, sm: 1 / 2 };
        case "columns3":
            return { base: 1, lg: 1 / 3 };
        case "columns4":
            return { base: 1, lg: 1 / 4 };
        default:
            return { base: 1, lg: 2 / 3 };
    }
};

const TeaserCardImage = (props: TeaserCardProps) => {
    const sizes = useImageSizes(getVariantImageSize(props.variant));

    if (!props.pageEditing && !props.fields?.Image?.value?.src) {
        return null;
    }

    return (
        <AspectRatio
            ratio={4 / 3}
            borderRadius={"base"}
            overflow={"hidden"}
            position="relative"
            gridColumn={
                props.variant === "imageLeft" || props.variant === "imageRight"
                    ? "span 2"
                    : undefined
            }
            order={{ base: undefined, lg: props.variant === "imageRight" ? 2 : undefined }}
        >
            <SCImage
                imageField={props.fields?.Image}
                sizes={sizes}
                {...imageSize(Number(props.fields?.Image?.value?.width), 4, 3)}
            />
        </AspectRatio>
    );
};

const isHTML = (str: string): boolean => {
    // Simple non-backtracking regex to detect HTML tags
    const safeHtmlRegex = /[<>]/;
    return safeHtmlRegex.test(str);
};

const gtmLinkText = "Grid teaser link";

const TeaserText = (props: TeaserCardProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    return (
        <>
            {!pageEditing && !isHTML(props.fields?.Text.value) ? (
                <SCText textField={props.fields?.Text} size="bodySmall" />
            ) : (
                <SCRichText richTextField={props.fields?.Text} basic />
            )}
        </>
    );
};

const TeaserCardCTA = (props: TeaserCardProps) => {
    const { t } = useI18n();
    const ButtonIcon = props.download ? (
        <BundleIcon name="Download" />
    ) : (
        <BundleIcon name="ArrowForward" />
    );
    return (
        <>
            {props.variant !== "columns3" && props.variant !== "columns4" ? (
                <SCLinkButton
                    linkField={props.fields?.CTA}
                    width="fit-content"
                    size="sm"
                    variant="primary"
                    mt={{ base: 0, lg: "sp24" }}
                    rightIcon={ButtonIcon}
                    onClick={() => {
                        if (!props.download) {
                            gtmCTAEvent(
                                props.fields?.Headline.value,
                                gtmLinkText,
                                props.fields?.CTA.value.href
                            );
                        } else {
                            gtmCustomEvent("custom_event", {
                                type: "file download",
                                name: props.fields?.CTA?.value?.href,
                            });
                        }
                    }}
                    {...(props.download && {
                        download: props.fields?.CTA?.value?.href,
                    })}
                />
            ) : (
                <>
                    {!props.pageEditing && props.fields?.CTA?.value?.href !== "" ? (
                        <Link
                            as="span"
                            fontWeight="medium"
                            letterSpacing="wide"
                            my="sp12"
                            variant="underlineLink"
                            width={"fit-content"}
                            size="sm"
                        >
                            {props.fields?.CTA.value.text !== ""
                                ? props.fields?.CTA.value.text
                                : t("readTheArticle")}
                        </Link>
                    ) : (
                        <SCLink
                            linkField={props.fields?.CTA}
                            variant="link"
                            width={"fit-content"}
                            size={"sm"}
                            onClick={() => {
                                gtmCTAEvent(
                                    props.fields?.Headline.value,
                                    gtmLinkText,
                                    props.fields?.CTA.value.href
                                );
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};

const TeaserCardContent = (props: TeaserCardProps) => {
    return (
        <Stack
            spacing="sp16"
            mt={
                props.variant === "imageLeft" || props.variant === "imageRight" ? "auto" : undefined
            }
            {...(props.variant === "imageLeft" && { gridColumn: "3" })}
        >
            <SCText textField={props.fields?.Kicker} size={"eyebrowSmall"} />
            <SCHeading
                {...(props.id && { id: props.id })}
                textField={props.fields?.Headline}
                as="h2"
                lineHeight={"shorter"}
                size={{
                    base: "h4",
                    lg: props.variant === "columns3" || props.variant === "columns4" ? "h5" : "h4",
                }}
            />
            <TeaserText {...props} />
            <TeaserCardCTA {...props} />
        </Stack>
    );
};

const TeaserCard = (props: TeaserCardProps) => {
    const {
        sitecoreContext: { pageEditing },
    } = useSitecoreContext();

    if ((props.variant === "columns3" || props.variant === "columns4") && !pageEditing) {
        if (props.fields?.CTA.value?.href !== "") {
            return (
                <Link
                    as={NextLink}
                    href={props.fields?.CTA.value?.href}
                    gridColumn={{
                        base: "span 1",
                        lg: gridColumnValue(props.variant),
                    }}
                    display="flex"
                    flexDirection="column"
                    gap="sp16"
                    _hover={{
                        span: {
                            _before: {
                                right: "100%",
                            },
                        },
                    }}
                    onClick={() => {
                        gtmCTAEvent(
                            props.fields?.Headline.value,
                            "Grid teaser link",
                            props.fields?.CTA.value.href
                        );
                    }}
                >
                    <TeaserCardImage {...props} pageEditing={pageEditing} />
                    <TeaserCardContent {...props} pageEditing={pageEditing} />
                </Link>
            );
        } else {
            return (
                <Box
                    gridColumn={{
                        base: "span 1",
                        lg: gridColumnValue(props.variant),
                    }}
                    flexDirection="column"
                    display="flex"
                    gap="sp16"
                >
                    <TeaserCardImage {...props} pageEditing={pageEditing} />
                    <TeaserCardContent {...props} pageEditing={pageEditing} />
                </Box>
            );
        }
    } else {
        return (
            <Box
                display={{ base: "flex", lg: "grid" }}
                flexDirection={{ base: "column", lg: undefined }}
                gridTemplateRows={{
                    base: undefined,
                    lg: props.variant === "columns2" ? "auto 1fr" : undefined,
                }}
                gridTemplateColumns={{
                    base: undefined,
                    lg:
                        props.variant === "imageLeft" || props.variant === "imageRight"
                            ? "repeat(3, 1fr)"
                            : undefined,
                }}
                rowGap="sp16"
                columnGap={{ base: 0, lg: "sp40" }}
                gridColumn={{
                    base: undefined,
                    lg: gridColumnValue(props.variant),
                }}
            >
                <TeaserCardImage {...props} pageEditing={pageEditing} />
                <TeaserCardContent {...props} pageEditing={pageEditing} />
            </Box>
        );
    }
};

export default TeaserCard;
