import type { ComponentParams, ComponentRendering } from "@sitecore-jss/sitecore-jss-nextjs";
import { Text as JssText } from "@sitecore-jss/sitecore-jss-nextjs";
import {
    Box,
    Button,
    Link,
    Heading,
    Stack,
    Text,
    AspectRatio,
    Flex,
    VisuallyHidden,
} from "@chakra-ui/react";
import type { GenericHeaderProps } from "commons/ui/GenericHeader";
import GenericHeader from "commons/ui/GenericHeader";
import { useCallback, useEffect, useRef, useState } from "react";
import NextImage from "next/image";
import useImageSizes from "utils/hooks/useImageSizes";
import NextLink from "next/link";
import { useI18n } from "next-localization";
import { dateFormatter } from "utils/dateFormatter";

import { useRouter } from "next/router";
import BundleIcon from "commons/ui/BundleIcon";
import { SkeletonContainerGrid } from "commons/ui/SkeletonContainer";
import { FilterLoadmoreHandler } from "commons/ui/FilterUI";

interface SortOptionProps {
    jsonValue: {
        displayName: string;
        fields: {
            Key: {
                value: string;
            };
        };
        id: string;
        name: string;
        url: string;
    };
}

interface FilterTagCategoryProps {
    targetItems: {
        name: string;
        displayName: string;
        fields: {
            Title: {
                value: string;
            };
        };
        children: {
            results: {
                id: string;
                title: {
                    jsonValue: {
                        value: string;
                    };
                };
            }[];
        };
    }[];
}

interface Fields extends GenericHeaderProps {
    taglinesFilterStartItem: {
        jsonValue: {
            id: string;
        };
    };
    taglinesFilter: {
        jsonValue: {
            id: string;
        }[];
    };
    sortOption: SortOptionProps;
    filterTagCategories: FilterTagCategoryProps;
}

type PageFieldValue = {
    jsonValue: {
        alt: string;
        src: string;
        displayName: string;
        value: string;
    };
};

interface DynamicLinkResult {
    id: string;
    pageListTagline: PageFieldValue;
    pageListHeadline: PageFieldValue;
    pageListTeaserText: PageFieldValue;
    pageListImage: {
        jsonValue: {
            value: {
                alt: string;
                src: string;
                width: number;
                height: number;
            };
        };
    };
    date: PageFieldValue;
    endDate: PageFieldValue;
    url: {
        url: string;
        path: string;
    };
}

interface DynamicLinkListResult {
    results: DynamicLinkResult[];
    totalResults: number;
    firstNewResultIndex: number;
    endCursor: string;
    hasNext: boolean;
}

export type DynamicLinkListProps = {
    rendering: ComponentRendering & { params: ComponentParams };
    params: { [key: string]: string };
    fields: {
        data: {
            item: Fields;
        };
    };
};

const numberOfResultsPerPage = 12;

const DynamicLinkList = (props: DynamicLinkListProps) => {
    const fieldData = props.fields.data.item;
    const sizes = useImageSizes({ base: 1, sm: 1 / 2, lg: 1 / 3 });
    const [dynamicListResults, setDynamicListResults] = useState<DynamicLinkListResult>({
        results: [],
        totalResults: 0,
        firstNewResultIndex: -1,
        endCursor: "",
        hasNext: false,
    });
    const { push, query, isReady } = useRouter();
    const [queryString, setQueryString] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [openTagCategory, setOpenTagCategory] = useState<string>("");

    const { t } = useI18n();
    const firstNewResultRef = useRef<HTMLAnchorElement>(null);

    const fetchResults = useCallback(async () => {
        setLoading(true);

        try {
            const queryDidChange = JSON.stringify(query) !== queryString;
            const tagIds = fieldData?.filterTagCategories?.targetItems
                ?.map((filterTagCategory) => {
                    const selectedTagId = query?.[encodeURIComponent(filterTagCategory.name)];
                    return selectedTagId ? (selectedTagId as string) : null;
                })
                .filter((tagId) => tagId !== null);
            const requestBody = {
                tagLineStartId: fieldData?.taglinesFilterStartItem?.jsonValue?.id,
                tagIds,
                tagLineIds: fieldData?.taglinesFilter?.jsonValue.map((tagLine) => tagLine?.id),
                numberOfResultsPerPage,
                afterId: !queryDidChange ? dynamicListResults.endCursor : "",
                sortOption: fieldData?.sortOption?.jsonValue?.fields?.Key?.value,
            };

            const response = await fetch(`/api/dynamic-link-list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            const filteredResults = responseData.results?.filter(
                (item: DynamicLinkResult) => item !== null
            ) as DynamicLinkResult[];

            setDynamicListResults((prevResults) => ({
                results: queryDidChange
                    ? [...filteredResults]
                    : [...prevResults.results, ...filteredResults],
                totalResults: responseData.total,
                firstNewResultIndex: queryDidChange ? 0 : prevResults.results.length,
                endCursor: responseData.endCursor,
                hasNext: responseData.hasNext,
            }));
            setLoading(false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("error fetching data", error);
        } finally {
            setLoading(false);
        }
    }, [
        dynamicListResults.endCursor,
        fieldData?.filterTagCategories?.targetItems,
        fieldData?.sortOption?.jsonValue?.fields?.Key?.value,
        fieldData?.taglinesFilter?.jsonValue,
        fieldData?.taglinesFilterStartItem?.jsonValue?.id,
        query,
        queryString,
    ]);

    const onToggleTag = (name: string) => {
        if (name === openTagCategory) {
            setOpenTagCategory("");
        } else {
            setOpenTagCategory(name);
        }
    };
    const onSelectTag = (categoryName: string, tagId: string) => {
        const updatedQuery = { ...query };
        let existingTags = (updatedQuery[encodeURIComponent(categoryName)] as string[]) || [];
        if (existingTags && typeof existingTags === "string") {
            existingTags = [existingTags];
        }

        const tagIndex = existingTags.indexOf(tagId);
        if (tagIndex !== -1) {
            existingTags.splice(tagIndex, 1);
        } else {
            existingTags.push(tagId);
        }

        updatedQuery[encodeURIComponent(categoryName)] = existingTags;
        push({ query: updatedQuery }, undefined, { shallow: true });
    };
    const cleanAllFilters = () => {
        setOpenTagCategory("");
        push({ query: { path: query["path"] } }, undefined, { shallow: true });
    };

    useEffect(() => {
        if (isReady) {
            fetchResults();
            setQueryString(JSON.stringify(query));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, isReady]);

    useEffect(() => {
        if (
            isReady &&
            firstNewResultRef?.current &&
            dynamicListResults.hasNext &&
            dynamicListResults.results.length > numberOfResultsPerPage
        ) {
            firstNewResultRef?.current.focus();
            firstNewResultRef?.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
    }, [
        dynamicListResults.firstNewResultIndex,
        dynamicListResults.hasNext,
        dynamicListResults.results.length,
        isReady,
    ]);

    const getLoadMoreButtonLabel = ({
        t,
        loading,
        hasNext,
    }: {
        t: (key: string) => string;
        loading: boolean;
        hasNext: boolean;
    }): string => {
        if (loading) return t("loadMoreLoadingA11yText");
        if (hasNext) return t("fetchMoreResultsA11yText");
        return "";
    };

    const showClearAll = query && Object.keys(query).filter((e) => e !== "path").length > 0;
    const showing = dynamicListResults.results.length;
    const totalResults = dynamicListResults?.totalResults ?? 0;

    if (!props.fields.data.item) return null;

    return (
        <GenericHeader
            Id={props.rendering?.uid ?? props.rendering?.componentName}
            AnchorId={props.fields?.data?.item?.AnchorId}
            HeaderHeadline={props.fields?.data?.item?.HeaderHeadline}
            HeaderSubHeadline={props.fields?.data?.item?.HeaderSubHeadline}
            HeaderCTA={props.fields?.data?.item?.HeaderCTA}
            headlineSize={props.params?.HeaderHeadlineSize}
            spacingSize={props.params?.Padding}
        >
            {!!props.fields.data.item?.filterTagCategories?.targetItems.length && (
                <>
                    <Flex
                        gap={16}
                        pb={"sp24"}
                        mb={"sp24"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        position={"relative"}
                        borderBottom={"1px solid lightGray"}
                        flexWrap={"wrap"}
                    >
                        <Text width={{ base: "full", md: "auto" }}>
                            {t("showingOfArticlesText", {
                                showing: showing,
                                totalResults: totalResults,
                            })}
                        </Text>
                        <Flex
                            gap={16}
                            alignItems={"center"}
                            flexWrap={"wrap"}
                            width={{ base: "full", md: "auto" }}
                        >
                            <Flex
                                width={{ base: "100%", md: "auto" }}
                                flexDir={{ base: "row-reverse", md: "inherit" }}
                                justifyContent={{ base: "space-between", md: "inherit" }}
                            >
                                {showClearAll && (
                                    <Link
                                        as="button"
                                        variant={"clearAllLink"}
                                        onClick={cleanAllFilters}
                                        mr="sp24"
                                    >
                                        {t("clearAllButtonText")}
                                    </Link>
                                )}
                                <Text>{t("filterLabelText")}</Text>
                            </Flex>
                            {props.fields.data.item?.filterTagCategories.targetItems?.map(
                                (filterTagCategory) => {
                                    const isOpen =
                                        openTagCategory === filterTagCategory.name.trim();
                                    const tags = query[
                                        encodeURIComponent(filterTagCategory.name.trim())
                                    ] as string[];
                                    const totalSelectedCategoryTags =
                                        typeof tags === "string" ? 1 : tags?.length;

                                    return (
                                        <Box key={filterTagCategory.name}>
                                            <Button
                                                variant={"outline"}
                                                size={"sm"}
                                                {...(isOpen && {
                                                    sx: {
                                                        span: {
                                                            transform: "rotate(180deg)",
                                                        },
                                                    },
                                                })}
                                                onClick={() => {
                                                    onToggleTag(filterTagCategory.name.trim());
                                                }}
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.key === "Enter" ||
                                                        (e.key === " " && isOpen)
                                                    ) {
                                                        const firstTag = document
                                                            .getElementById(
                                                                `tagcategorytags-${filterTagCategory.name.trim()}`
                                                            )
                                                            ?.querySelector("button");
                                                        if (firstTag) {
                                                            firstTag.focus();
                                                        }
                                                    }
                                                }}
                                                rightIcon={<BundleIcon name={"ExpandMore"} />}
                                                id={`tagcategorybutton-${filterTagCategory.name}`}
                                                aria-controls={`tagcategorytags-${filterTagCategory.name}`}
                                                aria-expanded={
                                                    openTagCategory ===
                                                    filterTagCategory.name.trim()
                                                }
                                            >
                                                {filterTagCategory.name.trim()}{" "}
                                                {!!totalSelectedCategoryTags &&
                                                    totalSelectedCategoryTags > 0 && (
                                                        <>
                                                            ({totalSelectedCategoryTags ?? 0})
                                                            <VisuallyHidden>
                                                                {" "}
                                                                {t("selectedTagsA11yText")}
                                                            </VisuallyHidden>
                                                        </>
                                                    )}
                                            </Button>
                                        </Box>
                                    );
                                }
                            )}
                        </Flex>
                    </Flex>
                    {fieldData?.filterTagCategories.targetItems?.map((filterTagCategory) => {
                        return (
                            <Flex
                                id={`tagcategorytags-${filterTagCategory.name}`}
                                aria-labelledby={`tagcategory-${filterTagCategory.name}`}
                                key={filterTagCategory.name}
                                {...(openTagCategory !== filterTagCategory.name.trim() && {
                                    hidden: true,
                                })}
                                borderBottom={"1px solid lightGray"}
                                pb={"sp24"}
                                gap={"sp16"}
                                flexWrap={"wrap"}
                                marginBottom={"sp40"}
                            >
                                {filterTagCategory.children.results?.map((tag) => {
                                    const selected = query[
                                        encodeURIComponent(filterTagCategory.name)
                                    ]?.includes(tag.id);
                                    return (
                                        <Button
                                            key={`${tag.id}_option`}
                                            variant={selected ? "primary" : "outline"}
                                            size={"xs"}
                                            onClick={() =>
                                                onSelectTag(filterTagCategory.name.trim(), tag.id)
                                            }
                                            aria-pressed={selected}
                                            {...(selected && {
                                                rightIcon: <BundleIcon name={"Check"} />,
                                            })}
                                        >
                                            {tag.title.jsonValue.value}
                                        </Button>
                                    );
                                })}
                            </Flex>
                        );
                    })}
                </>
            )}

            <VisuallyHidden as="output" aria-live="polite">
                {getLoadMoreButtonLabel({ t, loading, hasNext: dynamicListResults.hasNext })}
            </VisuallyHidden>

            <Box
                display="grid"
                gridTemplateRows="repeat(5, auto)"
                gridTemplateColumns={{ base: "1fr", sm: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
                gap="sp40"
                aria-busy={loading}
                id={props.rendering.uid}
            >
                {!loading && dynamicListResults.results.length === 0 && (
                    <Box textAlign={"center"} gridColumn={"1 / 4"} gridRow={"3 / 4"}>
                        <Heading as="h3" size="h4" mb={"sp16"}>
                            {t("noResultsHeading")}
                        </Heading>
                        <Text>{t("noResultsText")}</Text>
                        {showClearAll && (
                            <Link
                                as="button"
                                variant={"clearAllLink"}
                                onClick={cleanAllFilters}
                                mt="sp24"
                            >
                                {t("clearAllButtonText")}
                            </Link>
                        )}
                    </Box>
                )}

                {dynamicListResults?.results.map((item: DynamicLinkResult, index) => {
                    const isFirstNewResult = index === dynamicListResults.firstNewResultIndex;
                    let date = item?.date && dateFormatter(item?.date?.jsonValue.value);
                    const toDate = item?.endDate && dateFormatter(item?.endDate?.jsonValue.value);
                    if (date && toDate) {
                        date = `${date} - ${toDate}`;
                    }
                    return (
                        <Link
                            as={NextLink}
                            ref={isFirstNewResult ? firstNewResultRef : null}
                            display="grid"
                            gridTemplateRows="subgrid"
                            gap="sp16"
                            key={item?.id}
                            href={item?.url?.path}
                            id={item?.id}
                            _hover={{
                                span: {
                                    _before: {
                                        right: "100%",
                                    },
                                },
                            }}
                        >
                            <Flex as="article" minWidth="100%" flexDirection="column" gap="sp16">
                                <AspectRatio borderRadius="base" overflow="hidden" ratio={4 / 3}>
                                    {item?.pageListImage?.jsonValue?.value?.src ? (
                                        <NextImage
                                            src={item?.pageListImage?.jsonValue?.value?.src}
                                            alt={item?.pageListImage?.jsonValue?.value?.alt ?? ""}
                                            sizes={sizes}
                                            width={Number(
                                                item?.pageListImage?.jsonValue?.value?.width
                                            )}
                                            height={Number(
                                                Math.round(
                                                    item?.pageListImage?.jsonValue?.value?.width *
                                                        (3 / 4)
                                                )
                                            )}
                                            style={{
                                                objectFit: "cover",
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </AspectRatio>
                                <Stack spacing="sp16">
                                    <Box
                                        display="grid"
                                        gridTemplateColumns={"1fr auto"}
                                        alignItems="center"
                                    >
                                        <Text as="p" size="eyebrowSmall">
                                            <JssText
                                                field={{
                                                    value: item?.pageListTagline?.jsonValue
                                                        ?.displayName,
                                                }}
                                                editable={false}
                                            />
                                        </Text>
                                        {date && (
                                            <Text as="p" size="articleDate" flexGrow="1">
                                                {date}
                                            </Text>
                                        )}
                                    </Box>
                                    <Heading as="h3" size="h5">
                                        <JssText
                                            field={{
                                                value: item?.pageListHeadline?.jsonValue?.value,
                                            }}
                                            editable={false}
                                        />
                                    </Heading>
                                    <Text as="p" size="bodySmall">
                                        <JssText
                                            field={{
                                                value: item?.pageListTeaserText?.jsonValue?.value,
                                            }}
                                            editable={false}
                                        />
                                    </Text>
                                    <Text as="span" my="sp12" variant="link" w="fit-content">
                                        {t("readTheArticle")}
                                    </Text>
                                </Stack>
                            </Flex>
                        </Link>
                    );
                })}

                {loading && (
                    <SkeletonContainerGrid numberOfResultsPerPage={numberOfResultsPerPage} />
                )}
            </Box>

            <FilterLoadmoreHandler
                loading={loading}
                hasNext={dynamicListResults.hasNext}
                fetchSearchResults={fetchResults}
                ariaControls={props.rendering.uid}
            />
        </GenericHeader>
    );
};

export default DynamicLinkList;
