import { useState } from "react";
import { AspectRatio, Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { SkeletonContainer } from "commons/ui/SkeletonContainer";
import SCImage from "commons/sc/SCImage";
import type { Sponsor } from "components/SponsorList";
import SCText from "commons/sc/SCText";
import { type Field } from "@sitecore-jss/sitecore-jss-nextjs";

const createHandleImageLoad =
    (loaded: boolean[], setLoaded: React.Dispatch<React.SetStateAction<boolean[]>>) =>
    (index: number) => {
        const newState = [...loaded];
        newState[index] = true;
        setLoaded(newState);
    };

interface SponsorsSingleColumn {
    headline?: Field<string>;
    sponsors: Sponsor[];
    sizes: string;
}

interface SponsorsGridProps {
    headline?: Field<string>;
    sponsors: Sponsor[];
    sizes: string;
    columnTemplate: object;
    displayDescription: boolean;
}

export const SponsorsGrid = ({
    headline,
    sponsors,
    sizes,
    columnTemplate,
    displayDescription,
}: SponsorsGridProps) => {
    const [loaded, setLoaded] = useState(sponsors.map(() => false));
    const handleImageLoad = createHandleImageLoad(loaded, setLoaded);

    return (
        <Flex flexDir="column" gap="sp72">
            {headline && <SCText textField={headline} size="h4" />}
            <Grid templateColumns={columnTemplate} rowGap="sp16" columnGap="sp28">
                {sponsors?.map((sponsor, index) => (
                    <GridItem key={sponsor.id}>
                        <SkeletonContainer isLoaded={loaded[index]}>
                            <AspectRatio ratio={1}>
                                <SCImage
                                    imageField={sponsor.fields.Logo}
                                    sizes={sizes}
                                    fill
                                    onLoad={() => handleImageLoad(index)}
                                />
                            </AspectRatio>
                        </SkeletonContainer>
                        {displayDescription && (
                            <Box fontWeight="light" fontSize="sm" textAlign="center">
                                {sponsor.fields.Description.value}
                            </Box>
                        )}
                    </GridItem>
                ))}
            </Grid>
        </Flex>
    );
};

export const SponsorsSingleColumn = ({ headline, sponsors, sizes }: SponsorsSingleColumn) => {
    const [loaded, setLoaded] = useState(sponsors.map(() => false));
    const handleImageLoad = createHandleImageLoad(loaded, setLoaded);

    return (
        <Flex flexDir="column" gap="sp28">
            {sponsors?.map((sponsor, index) => (
                <Flex
                    gap="sp28"
                    key={sponsor.id}
                    flexDir={{ base: "column-reverse", md: "row" }}
                    w="100%"
                >
                    <SkeletonContainer isLoaded={loaded[index]} flexBasis="50%" h="100%" w="100%">
                        <AspectRatio ratio={1}>
                            <SCImage
                                imageField={sponsor.fields.Logo}
                                fill
                                sizes={sizes}
                                onLoad={() => handleImageLoad(index)}
                            />
                        </AspectRatio>
                    </SkeletonContainer>

                    <Box flexBasis="50%">
                        <Flex
                            flexDir="column"
                            justifyContent="center"
                            alignItems="center"
                            w="100%"
                            h="100%"
                            gap="sp16"
                        >
                            {headline && <SCText textField={headline} size="h4" />}
                            <Box fontWeight="light" fontSize="sm">
                                {sponsor.fields.Description.value}
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            ))}
        </Flex>
    );
};
