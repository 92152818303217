import type { FC } from "react";
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import type { ChakraStyledOptions } from "@chakra-ui/react";
import {
    Flex,
    Box,
    useMediaQuery,
    HStack,
    Link,
    Button,
    Container,
    IconButton,
} from "@chakra-ui/react";
import { SubNavigationStyling } from "./styles";
import { useI18n } from "next-localization";
import type { NavigationEvents } from "./events";
import { AnimatePresence } from "framer-motion";
import { MotionBox } from "utils/framer/motionbox";
import { useEventEmitter } from "utils/events";
import NextLink from "next/link";
import { useRouter } from "next/router";
import type { MainNavigationFieldsProps } from "../../components/MainNavigation/MainNavigation";
import { getNavigationText } from "../../components/MainNavigation/MainNavigation";
import BundleIcon from "commons/ui/BundleIcon";
import { useHeaderBackground } from "utils/contexts/headerBackgroundContext";
import { getRandomAnimatedGradient } from "themes/utils/gradients";
import { transparentize } from "polished";
import SubNavigationLevel from "./SubNavigationLevel";
import { BurgerNavIcon } from "commons/ui/BurgerNavIcon";

type OpenMenuData = {
    level: number;
    fields: MainNavigationFieldsProps;
};

type OpenMenuDataActionAdd = {
    type: "ADD";
    payload: OpenMenuData;
};

type OpenMenuDataActionSet = {
    type: "SET";
    payload: OpenMenuData[];
};

type OpenMenuDataActionWithoutPayload = {
    type: "CLEAR" | "REMOVE_LAST";
};

type OpenMenuDataAction =
    | OpenMenuDataActionWithoutPayload
    | OpenMenuDataActionSet
    | OpenMenuDataActionAdd;

function openMenuDataReducer(state: OpenMenuData[], action: OpenMenuDataAction) {
    switch (action.type) {
        case "ADD": {
            return [...state, action.payload];
        }
        case "SET": {
            return action.payload;
        }
        case "REMOVE_LAST": {
            state.length = state.length - 1;
            return [...state];
        }
        case "CLEAR": {
            return [];
        }
        default: {
            return state;
        }
    }
}

type SubNavigationProps = {
    navChildren: Array<MainNavigationFieldsProps> | undefined;
    disclosureProps: any; // eslint-disable-line
    activeNavChildren: MainNavigationFieldsProps[] | undefined;
    isOpen: boolean;
    handleFirstLevelClick: (fields: MainNavigationFieldsProps | undefined) => void;
    navRef: React.RefObject<HTMLDivElement>;
};

const ancestorOrSelfRouteArray: { level: number; fields: MainNavigationFieldsProps }[] = [];
const animationDelay = 350;

const SubNavigation: FC<SubNavigationProps> = ({
    navChildren,
    activeNavChildren,
    disclosureProps,
    isOpen,
    handleFirstLevelClick,
    navRef,
}) => {
    const [openLevel, setOpenLevel] = useState<number>(0);
    const [hasAncestorOrSelfActive, setHasAncestorOrSelfActive] = useState<boolean>(false);
    const [openMenuData, dispatchOpenMenuData] = useReducer(openMenuDataReducer, []);
    const [gradientState, setGradientState] = useState<ChakraStyledOptions | null>(null);
    const { t } = useI18n();

    const { hasFullBackground, hasWhiteBackground } = useHeaderBackground();

    const closeButtonsRef = useRef<NodeListOf<HTMLButtonElement> | null>(null);
    const subNavsRef = useRef<NodeListOf<HTMLDivElement> | null>(null);

    const router = useRouter();
    const currentPage = router.asPath;

    const activeItemClass = " mainnav__item--active";
    const openItemClass = " mainnav__item--open";
    const [isLgBreakpointOrAbove] = useMediaQuery("(min-width: 62em)");

    const eventEmitter = useEventEmitter<NavigationEvents>("navigation");

    const refsById = useMemo(() => {
        const refs: React.RefObject<HTMLElement>[] = [];
        navChildren?.forEach(() => {
            refs.push(React.createRef());
        });
        return refs;
    }, [navChildren]);

    useEffect(() => {
        if (navChildren && navChildren.length > 0) {
            setHasAncestorOrSelfActive(navChildren.some((item) => item.Href === currentPage));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navChildren]);

    const closeSubNav = useCallback(() => {
        const { onClose } = disclosureProps;
        onClose();

        if (openMenuData.length) {
            for (const ref of refsById) {
                if (
                    ref.current?.innerText.toLowerCase() ===
                    String(openMenuData[0].fields?.NavigationTitle?.value).toLowerCase()
                ) {
                    ref.current?.focus();
                    break;
                }
            }
        }

        // Telling the header to render the theme logo
        eventEmitter.emit("onNavigationOpen", false);

        ancestorOrSelfRouteArray.length = 0;

        // Resetting the navigation when closing
        setOpenLevel(0);
        dispatchOpenMenuData({ type: "CLEAR" });
    }, [disclosureProps, eventEmitter, openMenuData, refsById]);

    const keyUp = (e: KeyboardEvent) => {
        if (e.key === "Escape" && isOpen) {
            closeSubNav();
        }
    };

    useEffect(() => {
        window.addEventListener("keyup", keyUp);

        return () => {
            window.removeEventListener("keyup", keyUp);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        closeSubNav();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router]);

    useEffect(() => {
        if (navRef.current && openMenuData.length > 0) {
            closeButtonsRef.current =
                navRef.current.querySelectorAll<HTMLButtonElement>(".closebutton");
            subNavsRef.current = navRef.current.querySelectorAll<HTMLDivElement>(".subnav__block");

            closeButtonsRef.current.forEach((button) => {
                button.classList.remove("show");
            });

            const activeSubNavs = Array.from(subNavsRef.current).filter(
                (x) => x.getAttribute("aria-expanded") === "true"
            );
            const latestSubNav = activeSubNavs[activeSubNavs.length - 1];

            if (latestSubNav) {
                const closebutton = latestSubNav.querySelector<HTMLButtonElement>(".closebutton");

                if (closebutton) {
                    closebutton.classList.add("show");
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openMenuData]);

    const generateGradient = useCallback(() => {
        const newGradient = getRandomAnimatedGradient();
        setGradientState(newGradient);
        if (newGradient?.sx._before.background !== gradientState?.sx._before.background) {
            return newGradient;
        } else {
            generateGradient();
            return false;
        }
    }, [gradientState?.sx._before.background]);

    const findActiveAncestorOrSelf = useCallback(
        (fields: MainNavigationFieldsProps[]) => {
            let updateState = true;

            fields?.forEach((fields) => {
                if (fields.Href === currentPage) {
                    ancestorOrSelfRouteArray.push({
                        level: ancestorOrSelfRouteArray.length,
                        fields: fields,
                    });

                    if (fields.Children && fields.Children.length > 0) {
                        findActiveAncestorOrSelf(fields.Children);

                        updateState = false;
                    }
                }
            });

            if (!updateState) {
                return;
            }

            if (ancestorOrSelfRouteArray.length > 0) {
                dispatchOpenMenuData({ type: "SET", payload: ancestorOrSelfRouteArray });
            }
        },
        [currentPage]
    );

    const diffCountFunc = ({
        index,
        diffCount,
        animationInterval,
        openMenuDataMutation,
        fields,
        level,
    }: {
        index: number;
        diffCount: number;
        openMenuDataMutation: OpenMenuData[];
        animationInterval: NodeJS.Timeout | null;
        fields: MainNavigationFieldsProps | null;
        level: number;
    }) => {
        if (index === diffCount) {
            if (animationInterval) clearInterval(animationInterval);

            if (fields) {
                openMenuDataMutation.forEach((activeItem: OpenMenuData) => {
                    // If clicking is on an item of an already existing level
                    if (activeItem.level === level) {
                        activeItem.fields = fields;
                    }
                });

                dispatchOpenMenuData({
                    type: "SET",
                    payload: [...openMenuDataMutation, { level, fields }],
                });
            }
        }
    };
    const intervalLevel = useCallback(
        ({
            diffCount,
            openMenuDataMutation,
            fields,
            level,
        }: {
            diffCount: number;
            openMenuDataMutation: OpenMenuData[];
            fields: MainNavigationFieldsProps | null;
            level: number;
        }) => {
            let index = 0;
            let animationInterval: any = null; // eslint-disable-line

            const animationIntervalCallback = () => {
                // If the length of our array is different that the diffCount + 1 (to close the correct number of levels)
                if (index !== diffCount && openMenuDataMutation.length > 0) {
                    openMenuDataMutation.length = openMenuDataMutation.length - 1;
                }

                // Updating the state to reflect changes in the SubNavigationLevel components
                dispatchOpenMenuData({ type: "SET", payload: [...openMenuDataMutation] });

                // If we reached the end of our loop we will stop and update state accordingly
                diffCountFunc({
                    index,
                    diffCount,
                    animationInterval,
                    openMenuDataMutation,
                    fields,
                    level,
                });
                index++;
            };

            // First state update to avoid the interval delay
            animationIntervalCallback();

            // Only if we are showing the desktop navigation we might need to close more levels than one at a time
            if (isLgBreakpointOrAbove) {
                // The rest of the state updates to take the delay into account
                animationInterval = setInterval(animationIntervalCallback, animationDelay);
            }
        },
        [dispatchOpenMenuData, isLgBreakpointOrAbove]
    );

    const handleLevelChange = useCallback(
        (level: number, fields: MainNavigationFieldsProps | null) => {
            const openMenuDataMutation = [...openMenuData];

            // If we are clicking on an item already in the active object array
            if (openMenuDataMutation.some((item) => item.fields === fields)) {
                return;
            }

            setOpenLevel(level);

            // Getting the difference between the highest active level and the clicked level for later use
            const diffCount = openMenuDataMutation.length - level;

            if (openMenuDataMutation.length > level) {
                intervalLevel({ diffCount, openMenuDataMutation, fields, level });
            } else if (fields) {
                // If we add an object to the active array by navigating to the next level
                dispatchOpenMenuData({
                    type: "SET",
                    payload: [...openMenuDataMutation, { level, fields }],
                });
            }

            if (ancestorOrSelfRouteArray.length > 0) {
                ancestorOrSelfRouteArray.length = 0;
            }
        },
        [intervalLevel, openMenuData]
    );

    const openSubNavigation = useCallback(
        (fields: MainNavigationFieldsProps) => {
            if (activeNavChildren === fields.Children && isOpen) {
                closeSubNav();
                return;
            }

            if (isLgBreakpointOrAbove) generateGradient();

            if (hasAncestorOrSelfActive && navChildren) {
                findActiveAncestorOrSelf(navChildren);

                // Resetting the route active state since its purpose has been fulfilled
                setHasAncestorOrSelfActive(false);
            }

            if (
                ancestorOrSelfRouteArray.length === 0 ||
                ancestorOrSelfRouteArray[0].fields !== fields
            ) {
                dispatchOpenMenuData({ type: "SET", payload: [{ level: 0, fields }] });
                handleLevelChange(0, fields);
                handleFirstLevelClick(fields);
            } else {
                setOpenLevel(ancestorOrSelfRouteArray.length - 1);
                handleFirstLevelClick(ancestorOrSelfRouteArray[0].fields);
            }
        },
        [
            activeNavChildren,
            isOpen,
            isLgBreakpointOrAbove,
            generateGradient,
            hasAncestorOrSelfActive,
            navChildren,
            closeSubNav,
            findActiveAncestorOrSelf,
            handleLevelChange,
            handleFirstLevelClick,
        ]
    );

    const openSubNavigationBurger = () => {
        if (hasAncestorOrSelfActive && navChildren) {
            findActiveAncestorOrSelf(navChildren);

            // Resetting the route active state since its purpose has been used
            setHasAncestorOrSelfActive(false);
        }

        if (ancestorOrSelfRouteArray.length === 0) {
            handleLevelChange(0, null);
            handleFirstLevelClick(undefined);
        } else {
            setOpenLevel(ancestorOrSelfRouteArray.length - 1);
            handleFirstLevelClick(ancestorOrSelfRouteArray[0].fields);
        }
    };

    const closeSubNavigationBurger = () => {
        closeSubNav();
    };

    const motionVariants = {
        open: {
            opacity: 1,
            display: "flex",
            minHeight: isLgBreakpointOrAbove ? "85vh" : "100vh",
            paddingTop: isLgBreakpointOrAbove ? "var(--sizes-headerTopHeight)" : 0,
        },
        closed: {
            opacity: isLgBreakpointOrAbove ? 1 : 0,
            transitionEnd: { display: "none" },
            minHeight: isLgBreakpointOrAbove ? 0 : "100vh",
            paddingTop: 0,
        },
    };

    const transition = {
        default: {
            ease: [0.35, 1, 0.45, 1],
            duration: 0.3,
        },
    };

    useEffect(() => {
        if (!isLgBreakpointOrAbove && openLevel === 0) {
            generateGradient();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLgBreakpointOrAbove, openLevel]);

    const mainNavigationList = useMemo(() => {
        return navChildren?.map((fields: MainNavigationFieldsProps, index: number) => {
            const isActive: boolean =
                openMenuData.some((item) => item.fields === fields) || fields.Href === currentPage;

            const isOpenStyle = isOpen ? openItemClass : "";
            const isActiveStyle = isActive ? activeItemClass : isOpenStyle;

            return (
                <Box key={`${fields.Id}`} as="li" listStyleType="none">
                    {fields.Children && fields.Children.length > 0 ? (
                        <Button
                            className={`mainnav__item ${
                                openMenuData.some((item) => item.fields === fields) ||
                                fields.Href === currentPage
                                    ? activeItemClass
                                    : isOpenStyle
                            }`}
                            variant={"unstyled"}
                            onClick={() => openSubNavigation(fields)}
                            ref={refsById[index] as never}
                            aria-controls={fields.Id}
                            aria-expanded={isActive}
                        >
                            {getNavigationText(fields)}
                            <BundleIcon name="ExpandMore" />
                        </Button>
                    ) : (
                        <Link
                            as={NextLink}
                            href={fields.Href}
                            ref={refsById[index] as never}
                            onClick={closeSubNav}
                            className={`mainnav__item ${isActiveStyle} link`}
                        >
                            {getNavigationText(fields)}
                        </Link>
                    )}
                </Box>
            );
        });
    }, [closeSubNav, currentPage, isOpen, navChildren, openMenuData, openSubNavigation, refsById]);

    return (
        <>
            <BurgerNavIcon
                color={!hasFullBackground || hasWhiteBackground ? "black" : "white"}
                label={!isOpen ? t("mobileMenuButtonOpen") : t("mobileMenuButtonClose")}
                ariaControls="main_navigation"
                isOpen={isOpen}
                onToggle={isOpen ? closeSubNavigationBurger : openSubNavigationBurger}
            />

            <HStack
                id="main_navigation"
                as="ul"
                gap={["sp8", null, null, "sp24", "sp32"]}
                display={["none", null, null, "flex"]}
                aria-label={t("topLevelNavigationLabel")}
                position="relative"
                zIndex={100}
            >
                {mainNavigationList}
            </HStack>

            <AnimatePresence>
                {isOpen && (
                    <MotionBox
                        animate={isOpen ? "open" : "closed"}
                        variants={motionVariants}
                        initial="closed"
                        transition={transition}
                        className="subnav__wrapper"
                        boxShadow={`0px 50px 56px 0px ${transparentize(0.8, "black")}`}
                        position={"relative"}
                        sx={{
                            ...gradientState?.sx,
                            ...SubNavigationStyling.sx,
                            "&:before": {
                                transition: ["all 0.2s ease 0.15s", null, null, "all 0.2s"],
                                opacity: [openLevel > 0 ? 0.3 : 0, null, null, 1],
                            },
                        }}
                        display={"flex"}
                        flexDir={"column"}
                    >
                        <>
                            <MotionBox
                                animate={isOpen ? "open" : "closed"}
                                initial="closed"
                                transition={transition}
                                className="subnav__wrapper--inner"
                            >
                                <Container
                                    variant={"header"}
                                    position={"relative"}
                                    px={[null, null, null, "sp80"]}
                                >
                                    <Flex
                                        className="subnav__inner"
                                        mx={[0, null, null, "-sp24"]}
                                        {...disclosureProps}
                                    >
                                        {navChildren &&
                                            isLgBreakpointOrAbove &&
                                            navChildren.map(
                                                (
                                                    subNavItem: MainNavigationFieldsProps,
                                                    subNavItemIndex: number
                                                ) => (
                                                    <SubNavigationLevel
                                                        index={0}
                                                        key={`${subNavItem.Id}`}
                                                        parentItem={subNavItem}
                                                        Children={subNavItem.Children}
                                                        open={openMenuData.some(
                                                            (item) => item.fields === subNavItem
                                                        )}
                                                        disclosureProps={disclosureProps}
                                                        closeSubNav={closeSubNav}
                                                        handleLevelChange={handleLevelChange}
                                                        activeIndex={openLevel}
                                                        activeMenuData={openMenuData}
                                                        isLgBreakpointOrAbove={
                                                            isLgBreakpointOrAbove
                                                        }
                                                        subNavItemIndex={subNavItemIndex}
                                                    />
                                                )
                                            )}
                                        {navChildren && !isLgBreakpointOrAbove && (
                                            <SubNavigationLevel
                                                index={0}
                                                Children={navChildren}
                                                open={true}
                                                disclosureProps={disclosureProps}
                                                closeSubNav={closeSubNav}
                                                handleLevelChange={handleLevelChange}
                                                activeIndex={openLevel}
                                                activeMenuData={openMenuData}
                                                isLgBreakpointOrAbove={isLgBreakpointOrAbove}
                                            />
                                        )}
                                    </Flex>
                                </Container>
                            </MotionBox>
                            <Container variant={"header"} className="closeButtonContainer">
                                <IconButton
                                    aria-label={t("desktopNavigationClose")}
                                    onClick={closeSubNav}
                                    icon={<BundleIcon name="Close" />}
                                    variant={"unstyled"}
                                    marginRight={"sp16"}
                                />
                            </Container>{" "}
                        </>
                    </MotionBox>
                )}
            </AnimatePresence>
        </>
    );
};

export default SubNavigation;
