/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter } from "./eventEmitter";

const eventEmitters: any = {};
export function useEventEmitter<T extends { name: string }>(
    eventsName: T["name"]
): EventEmitter<Omit<T, "name">> {
    if (!eventEmitters[eventsName]) {
        eventEmitters[eventsName] = new EventEmitter<T>();
    }
    return eventEmitters[eventsName];
}
