import type { Field, ImageField } from "@sitecore-jss/sitecore-jss-nextjs";
import Head from "next/head";

type MetaDataProps = {
    MetadataCanonicalUrl: string;
    MetadataCanonicalUrlManualOverride?: {
        value: {
            href: string;
        };
    };
    MetadataPageTitle?: Field<string>;
    MetadataDescription?: Field<string>;
    MetadataRobotsNoFollow?: Field<boolean>;
    MetadataRobotsNoIndex?: Field<boolean>;
    OpenGraphTitle?: Field<string>;
    OpenGraphDescription?: Field<string>;
    OpenGraphImage?: ImageField;
};

const MetaData = (props: MetaDataProps) => {
    const canincalUrl =
        props.MetadataCanonicalUrlManualOverride?.value?.href !== ""
            ? props.MetadataCanonicalUrlManualOverride?.value?.href
            : props.MetadataCanonicalUrl;
    return (
        <Head>
            <title>
                {props.MetadataPageTitle?.value !== ""
                    ? props.MetadataPageTitle?.value + " | SimCorp"
                    : "SimCorp"}
            </title>
            <meta name="description" content={props.MetadataDescription?.value} />
            <meta
                name="robots"
                content={`${props.MetadataRobotsNoIndex?.value ? "noindex" : "index"},${
                    props.MetadataRobotsNoFollow?.value ? "nofollow" : "follow"
                }`}
            />
            <link rel="canonical" href={`${canincalUrl}`} />
            {props.OpenGraphTitle?.value !== "" && (
                <meta property="og:title" content={props.OpenGraphTitle?.value} />
            )}

            {props.OpenGraphDescription?.value !== "" && (
                <meta property="og:description" content={props.OpenGraphDescription?.value} />
            )}

            {props.OpenGraphImage?.value?.src !== "" && (
                <meta property="og:image" content={props.OpenGraphImage?.value?.src} />
            )}
        </Head>
    );
};

export default MetaData;
