import { EditingScripts } from "@sitecore-jss/sitecore-jss-nextjs";
// The BYOC bundle imports external (BYOC) components into the app and makes sure they are ready to be used
import Byoc from "src/byoc";
import CdpPageView from "commons/CdpPageView";
import FEAASScripts from "commons/FEAASScripts";

const Scripts = (): JSX.Element => {
    return (
        <>
            <Byoc />
            <CdpPageView />
            <FEAASScripts />
            <EditingScripts />
        </>
    );
};

export default Scripts;
